import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import moment from 'moment';

import { AngularFireDatabase } from '@angular/fire/compat/database';
import { DatabaseReference } from '@angular/fire/compat/database/interfaces';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// import { Storage, LocalStorage } from 'ionic-angular';
// if you've gone with the local installation approach, you'd use the following:

@Injectable({ providedIn: 'root' })
export class DataService {
  // Config REF
  public _config: any;
  public config: DatabaseReference;
  public devModus: boolean;
  public loading: boolean;

  // DATA
  public userProfile: any;
  public selectedYearSemester: any;

  // REFS
  public useraccountList: DatabaseReference;

  public authroleList: DatabaseReference;
  public authrolememberList: DatabaseReference;
  public authuserrolememberList: DatabaseReference;

  public userfunctionList: DatabaseReference;
  public userfunctionmemberList: DatabaseReference;
  public useruserfunctionmemberList: DatabaseReference;

  public teacherList: DatabaseReference;
  public supervisorList: DatabaseReference;
  public administratorList: DatabaseReference;
  public administrativeworkerList: DatabaseReference;
  public unknownuserList: DatabaseReference;

  public orderList: DatabaseReference;

  public personList: DatabaseReference;
  public personCompetenceList: DatabaseReference;
  public personPresenceList: DatabaseReference; //

  public childList: DatabaseReference;
  public newsList: DatabaseReference;
  public languageList: DatabaseReference;

  public courseList: DatabaseReference;
  public moduleList: DatabaseReference;

  public placementtestList: DatabaseReference;
  public placementtestPersonList: DatabaseReference;
  public personPlacementtestList: DatabaseReference;

  public classList: DatabaseReference;
  public classsectionList: DatabaseReference;
  public classPersonList: DatabaseReference;
  public personClassList: DatabaseReference;

  public waitinglistList: DatabaseReference;
  public waitinglistPersonList: DatabaseReference;
  public personWaitinglistList: DatabaseReference;

  public competenceprofileList: DatabaseReference;
  public classPersonPresenceList: DatabaseReference; //

  public translatorList: DatabaseReference;
  public userRequestList: DatabaseReference;
  public currentUser: string;
  public customerList: DatabaseReference;
  public orderCategoryList: DatabaseReference;
  public orderTypeList: DatabaseReference;
  public purchaseOrderTypeList: DatabaseReference;
  public nationalityList: DatabaseReference;
  public materialstatusList: DatabaseReference;
  public residencestatusList: DatabaseReference;
  public religionList: DatabaseReference;

  public journalList: DatabaseReference;

  private secondaryApp: any;
  // meeeeeeeeeee
  public userFunctionsMembers: any[] = []; // Cache

  // ValueLists
  public yearsemester: any;
  public literacylevelList: any;
  public mathNiveau: any;
  public verhaltensbeobachtungenNiveau: any;
  public niveauGermanLanguage: any;
  public niveauGermanLanguageAll: any;
  public genericScalaOnetoTen: any;
  public LernundLebenssituation: any;
  public Selbstbeurteilung: any;
  public Praesenz: any;
  public Lernangebot: any;
  public Klassenniveau: any;
  public lerngewohntheit: any;
  public filter: any = {
    persons: '',
    classes: '',
    waitinglists: ''
  };

  constructor(private afs: AngularFirestore, adb: AngularFireDatabase) {
    const that = this;
    this._config = {};
    this.config = adb.database.ref('_config');
    this.devModus = false;
    this.loading = false;
    this.selectedYearSemester = this.getYearSemester();
    // public
    this.useraccountList = adb.database.ref('useraccounts');
    this.authroleList = adb.database.ref('authroles');
    this.authrolememberList = adb.database.ref('authrolemembers');
    this.authuserrolememberList = adb.database.ref('authuserrolemembers');

    this.userfunctionList = adb.database.ref('userfunctions');
    this.userfunctionmemberList = adb.database.ref('userfunctionmembers');
    this.useruserfunctionmemberList = adb.database.ref('useruserfunctionmembers');

    this.teacherList = adb.database.ref('userfunctionmembers/teacher');

    this.orderList = adb.database.ref('tasks');
    this.personList = adb.database.ref('persons');
    this.personCompetenceList = adb.database.ref('personcompetences');
    this.personPresenceList = adb.database.ref('personpresence');

    this.childList = adb.database.ref('childrens');
    this.newsList = adb.database.ref('news/public/');
    this.languageList = adb.database.ref('languages');
    this.courseList = adb.database.ref('courses');
    this.moduleList = adb.database.ref('modules');
    this.classList = adb.database.ref('classes');
    this.classsectionList = adb.database.ref('classsections');

    this.translatorList = adb.database.ref('translators');
    this.userRequestList = adb.database.ref('userrequests');
    this.customerList = adb.database.ref('customers');
    this.orderCategoryList = adb.database.ref('orderCategories');
    this.orderTypeList = adb.database.ref('orderTypes');
    this.purchaseOrderTypeList = adb.database.ref('purchaseOrderTypes');
    this.nationalityList = adb.database.ref('nationalities');
    this.materialstatusList = adb.database.ref('materialstatus');
    this.residencestatusList = adb.database.ref('residencestatus');
    this.religionList = adb.database.ref('religions');

    this.supervisorList = adb.database.ref('userfunctionmembers/supervisor');
    this.administratorList = adb.database.ref('userfunctionmembers/administrator');
    this.administrativeworkerList = adb.database.ref('userfunctionmembers/administrativeworker');

    this.journalList = adb.database.ref('journal');

    this.classPersonList = adb.database.ref('classpersons');
    this.personClassList = adb.database.ref('personclass');
    this.competenceprofileList = adb.database.ref('competenceprofiles');

    this.waitinglistList = adb.database.ref('waitinglists');
    this.waitinglistPersonList = adb.database.ref('waitinglistpersons');
    this.personWaitinglistList = adb.database.ref('personwaitinglist');

    this.placementtestList = adb.database.ref('placementtests');
    this.placementtestPersonList = adb.database.ref('placementtestpersons');
    this.personPlacementtestList = adb.database.ref('personplacementtest');

    // config of application
    this.getConfig().once(
      'value',
      (snapshot) => {
        that._config = snapshot.val();
      },
      (err) => {
        // "error"
      }
    );

    // Cache füllen
    this.userfunctionmemberList.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        const object = snap.val();
        object.id = snap.key;
        this.userFunctionsMembers.push(object);
        // console.log(object)
      });
    });
    ///

    this.getValueHelpers();

    this.fix();

    // auth state
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // Login
        that
          .getUseraccountList()
          .child(user.uid)
          .on('value', (data) => {
            if (data.val()) {
              that.userProfile = data.val();
              that.userProfile.id = data.key;
            } else {
              console.log('nice try... :)');
              firebase.auth().signOut();
            }
          });
      } else {
        // Logout
        that.userProfile = null;
      }
    });

    let config = {};

    if (window.location.hostname === 'localhost1') {
      // TEST

      console.log('running secondary connection on test system');
      config = {
        apiKey: 'AIzaSyCH12aGjyw3HzC326_uJ97p8xdtz64ymTw',
        authDomain: 'office.sah-sh.ch',
        databaseURL: 'https://sah-i-test.firebaseio.com',
        storageBucket: 'sah-i-test.appspot.com',
        messagingSenderId: '459202515366'
      };
    } else {
      // PRODUCTION
      config = {
        apiKey: 'AIzaSyCsrUohUQx5BerpaWbt2fCF4kosMD_Tqms',
        authDomain: 'office.sah-sh.ch',
        databaseURL: 'https://sah-i-88870.firebaseio.com',
        storageBucket: 'sah-i-88870.appspot.com',
        messagingSenderId: '372696162975'
      };
    }
    this.secondaryApp = firebase.initializeApp(config, 'Secondary');
    /*************************************************************************/
    // Valuelists
    /*************************************************************************/
    this.yearsemester = [];
    this.yearsemester.push({ key: '2018-F', text: '2018-F' });
    this.yearsemester.push({ key: '2018-H', text: '2018-H' });
    this.yearsemester.push({ key: '2019-F', text: '2019-F' });
    this.yearsemester.push({ key: '2019-H', text: '2019-H' });
    this.yearsemester.push({ key: '2020-F', text: '2020-F' });
    this.yearsemester.push({ key: '2020-H', text: '2020-H' });
    this.yearsemester.push({ key: '2021-F', text: '2021-F' });
    this.yearsemester.push({ key: '2021-H', text: '2021-H' });
    this.yearsemester.push({ key: '2022-F', text: '2022-F' });
    this.yearsemester.push({ key: '2022-H', text: '2022-H' });
    this.yearsemester.push({ key: '2023-F', text: '2023-F' });
    this.yearsemester.push({ key: '2023-H', text: '2023-H' });
    this.yearsemester.push({ key: '2024-F', text: '2024-F' });
    this.yearsemester.push({ key: '2024-H', text: '2024-H' });
    this.yearsemester.push({ key: '2025-F', text: '2025-F' });
    this.yearsemester.push({ key: '2025-H', text: '2025-H' });
    this.yearsemester.push({ key: '2026-F', text: '2026-F' });
    this.yearsemester.push({ key: '2026-H', text: '2026-H' });
    this.yearsemester.push({ key: '2027-F', text: '2027-F' });
    this.yearsemester.push({ key: '2027-H', text: '2027-H' });
    this.yearsemester.push({ key: '2028-F', text: '2028-F' });
    this.yearsemester.push({ key: '2028-H', text: '2028-H' });
    this.yearsemester.push({ key: '2029-F', text: '2029-F' });
    this.yearsemester.push({ key: '2029-H', text: '2029-H' });
  }
  /*************************************************************************/
  // CONFIG
  /*************************************************************************/
  getConfig(): any {
    return this.config;
  }
  getYearSemester(): any {
    return '2024-H';
  }
  getNextYearSemester(): any {
    return '2025-F';
  }

  /*************************************************************************/
  // User Profile
  /*************************************************************************/
  getUserProfile(): any {
    return this.userProfile; // as Data, not as Ref
  }

  updateName(firstName: string, lastName: string, ex: boolean): any {
    return this.userProfile.update({
      firstName,
      lastName
    });
  }
  updateDOB(birthDate: string): any {
    return this.userProfile.update({
      birthDate
    });
  }
  saveUserProfile(userProfile): any {
    return this.userProfile.update({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      team: userProfile.team
    });
  }
  getUseraccountList(): any {
    return this.useraccountList;
  }
  createUseraccount(useraccount, password) {
    const that = this;
    // Transaction
    return this.secondaryApp
      .auth()
      .createUserWithEmailAndPassword(useraccount.email, password)
      .then((firebaseUser) => {
        console.log('User ' + firebaseUser.user.uid + ' created successfully!');
        console.log(firebaseUser.user.uid);
        // initialisieren und als User Berechtigen
        that
          .getUseraccountList()
          .child(firebaseUser.user.uid)
          .update(useraccount);
        that.getAuthrolememberList('users').update({ [firebaseUser.user.uid]: true });
        // that.getAuthrolememberList('users').child(firebaseUser.uid).update(true);
        that.getAuthuserrolememberList(firebaseUser.user.uid).update({ ['users']: true });
        // that.getAuthuserrolememberList(firebaseUser.uid).child('users').update(true);

        that.secondaryApp.auth().currentUser.updateEmail(useraccount.email);
        that.secondaryApp.auth().currentUser.updateProfile({ displayName: useraccount.firstname });

        // that.secondaryApp.sendEmailVerification();
        const availability = {
          weekday: {
            mo: {
              datefromAM: 0,
              datetoAM: 1200,
              datefromPM: 1200,
              datetoPM: 2359,
              fullday: true,
              wekkday: 'mo'
            },
            di: {
              datefromAM: 0,
              datetoAM: 1200,
              datefromPM: 1200,
              datetoPM: 2359,
              fullday: true,
              wekkday: 'di'
            },
            mi: {
              datefromAM: 0,
              datetoAM: 1200,
              datefromPM: 1200,
              datetoPM: 2359,
              fullday: true,
              wekkday: 'mi'
            },
            do: {
              datefromAM: 0,
              datetoAM: 1200,
              datefromPM: 1200,
              datetoPM: 2359,
              fullday: true,
              wekkday: 'do'
            },
            fr: {
              datefromAM: 0,
              datetoAM: 1200,
              datefromPM: 1200,
              datetoPM: 2359,
              fullday: true,
              wekkday: 'fr'
            },
            sa: {
              datefromAM: 0,
              datetoAM: 1200,
              datefromPM: 1200,
              datetoPM: 2359,
              fullday: true,
              wekkday: 'sa'
            },
            so: {
              datefromAM: 0,
              datetoAM: 1200,
              datefromPM: 1200,
              datetoPM: 2359,
              fullday: true,
              wekkday: 'so'
            }
          }
        };

        that.afs.doc('availability/' + firebaseUser.user.uid).set(availability, { merge: true });
        that.afs
          .doc('dolmetscher/' + firebaseUser.user.uid)
          .set({ interpreter: null, classification: 'C', ...useraccount }, { merge: true });

        // wieder abmelden, user ist sauber angelegt
        that.secondaryApp.auth().signOut();
      });
  }
  getAuthroleList(): any {
    return this.authroleList;
  }
  getAuthrolememberList(role): any {
    return this.authrolememberList.child(role);
  }
  getAuthuserrolememberList(uid): any {
    return this.authuserrolememberList.child(uid);
  }
  saveAuthrolememberList(uid, role, action, auth): any {
    this.authuserrolememberList
      .child(uid)
      .child(action)
      .update({
        [role]: auth
      });
    return this.authrolememberList
      .child(role)
      .child(action)
      .update({
        [uid]: auth
      })
      .then((data) => {
        this.createjournal('authrolemember', 'U', role, 'useraccount', uid, '');
      });
  }

  getUserfunctionList(): any {
    return this.userfunctionList;
  }

  // getUserfunctionmemberListList(){
  //   return this.userfunctionmemberList
  // }

  getUserfunctionmemberList(role): any {
    // userFunctionsMembers // wir wollen die richtige Zeile aus diesem Array.

    const index = this.userFunctionsMembers.map((e) => e.id).indexOf(role);
    if (index < 0) {
      return '';
    } else {
      // console.log(this.userFunctionsMembers[index])
      return this.userFunctionsMembers[index];
    }

    // return this.userfunctionmemberList.child(role);
  }
  getUseruserfunctionmemberList(uid): any {
    return this.useruserfunctionmemberList.child(uid);
  }
  saveUserfunctionmemberList(uid, userfunction, member): any {
    this.useruserfunctionmemberList.child(uid).update({
      [userfunction]: member
    });
    console.log(userfunction);
    if (userfunction === 'interpreter') {
      this.afs.doc('dolmetscher/' + uid).set({ interpreter: member }, { merge: true });
    }

    this.afs.doc('useruserfunctionmembers/' + uid).set({ [userfunction]: member }, { merge: true });

    return this.userfunctionmemberList
      .child(userfunction)
      .update({ [uid]: member })
      .then((data) => {
        this.createjournal('userfunction', 'U', userfunction, 'useraccount', uid, '');
      });
  }

  updateUseraccount(useraccount): any {
    this.afs.doc('dolmetscher/' + useraccount.id).set(useraccount, { merge: true });
    return this.getUseraccountList()
      .child(useraccount.id)
      .update(useraccount)
      .then((data) => {
        this.createjournal('useraccount', 'U', useraccount.id, '', '', '');
      });
  }
  deleteUseraccount(id): any {
    // this.getUseraccountList().child(id).remove();

    const that = this;
    that
      .getAuthrolememberList('users')
      .child(id)
      .remove();
    this.afs.doc('dolmetscher/' + id).set({ active: false, interpreter: false }, { merge: true });

    this.getAuthroleList().once('value', (snapshot) => {
      snapshot.forEach((snapshot2) => {
        that
          .getAuthrolememberList(snapshot2.key)
          .child('read')
          .child(id)
          .remove();
        that
          .getAuthrolememberList(snapshot2.key)
          .child('write')
          .child(id)
          .remove();
        that
          .getAuthrolememberList(snapshot2.key)
          .child('delete')
          .child(id)
          .remove();
      });
    });
    this.getAuthuserrolememberList(id).remove();

    this.getUserfunctionList().once('value', (snapshot) => {
      snapshot.forEach((snapshot2) => {
        that
          .getUserfunctionmemberList(snapshot2.key)
          .child(id)
          .remove();
      });
    });
    this.getUseruserfunctionmemberList(id).remove();

    return this.getUseraccountList()
      .child(id)
      .update({ active: false })
      .then((data) => {
        this.createjournal('useraccount', 'D', id, '', '', '');
      });
  }

  reactivateUseraccount(id): any {
    return this.getUseraccountList()
      .child(id)
      .update({ active: true })
      .then((data) => {
        this.createjournal('useraccount', 'A', id, '', '', '');
      });
  }
  getTeacherList(): any {
    return this.teacherList;
  }

  /*************************************************************************/
  // Allgemeine Daten
  /*************************************************************************/
  timeToDecimal(t) {
    if (t === '') {
      return 0;
    } else {
      t = t.split(':');
      return parseInt(t[0], 10) * 1 + parseInt(t[1], 10) / 60;
    }
  }
  /*************************************************************************/
  // User Order Requests
  /*************************************************************************/

  getUserRequestList(): any {
    return this.userRequestList;
  }

  /*************************************************************************/
  // Order List
  /*************************************************************************/

  getOrderList(): any {
    return this.orderList;
  }
  createOrder(order): any {
    return this.getOrderList().push(order);
  }
  updateOrder(order): any {
    return this.getOrderList()
      .child(order.id)
      .update(order);
  }
  deleteOrder(id): any {
    return this.getOrderList()
      .child(id)
      .remove();
  }

  getCustomerList(): any {
    return this.customerList;
  }
  getOrderCategoryList(): any {
    return this.orderCategoryList;
  }
  getOrderTypeList(): any {
    return this.orderTypeList;
  }
  getPurchaseOrderTypeList(): any {
    return this.purchaseOrderTypeList;
  }

  /*************************************************************************/
  // Person List
  /*************************************************************************/

  getPersonList(): DatabaseReference {
    return this.personList;
  }
  createPerson(person): any {
    // person.createdate = moment().format('YYYY-MM-DD');
    return this.getPersonList()
      .push(person)
      .then((item) => {
        this.createjournal('person', 'C', item.key, '', '', '');
        return item;
      })
      .then((item) => {
        this.createPersonCompetence(item.key);
        return item;
      });
  }
  updatePerson(person): any {
    person.changedate = moment().format('YYYY-MM-DD');
    if (person.birthdate && person.birthdate._isAMomentObject === true) {
      person.birthdate = person.birthdate.format('YYYY-MM-DD');
    }
    if (person.dateofimmigration && person.dateofimmigration._isAMomentObject === true) {
      person.dateofimmigration = person.dateofimmigration.format('YYYY-MM-DD');
    }
    if (person.dateofplacementtest && person.dateofplacementtest._isAMomentObject === true) {
      person.dateofplacementtest = person.dateofplacementtest.format('YYYY-MM-DD');
    }

    return this.getPersonList()
      .child(person.id)
      .update(person)
      .then((data) => {
        this.createjournal('person', 'U', person.id, '', '', '');
      });
  }
  deletePerson(id): any {
    return this.getPersonList()
      .child(id)
      .update({ active: false })
      .then((data) => {
        this.createjournal('person', 'D', id, '', '', '');
      });
  }
  undeletePerson(id): any {
    return this.getPersonList()
      .child(id)
      .update({ active: true })
      .then((data) => {
        this.createjournal('person', 'A', id, '', '', '');
      });
  }

  getChildList(personId): any {
    return this.childList.child(personId);
  }
  createChild(personId, child): any {
    return this.getChildList(personId)
      .push(child)
      .then((item) => {
        this.createjournal('child', 'C', item.key, 'person', personId, '');
      });
  }
  updateChild(personId, child): any {
    return this.getChildList(personId)
      .child(child.id)
      .update(child)
      .then((data) => {
        this.createjournal('child', 'U', child.id, 'person', personId, '');
      });
  }
  deleteChild(personId, id): any {
    return this.getChildList(personId)
      .child(id)
      .remove()
      .then((data) => {
        this.createjournal('child', 'D', id, 'person', personId, '');
      });
  }

  updatePersonKibis(personId, value, children): any {
    let kibis = false;
    if (value.length > 0) {
      kibis = true;
    }
    if (children?.length) {
      children = true;
    }
    return this.getPersonList()
      .child(personId)
      .update({ kibis, children });
  }

  /// Kompetenzen von Person
  getPersonCompetenceDetail(personId): any {
    return this.personCompetenceList.child(personId);
  }
  createPersonCompetence(personId): any {
    const competences = {
      stufederalphabetisierung: '',
      deutschniveau: '',
      lernundLebenssituation: '',
      selbsbeurteilungLernfortschritt: '',
      zufriedenheit: '',
      präsenz: '',
      empfehlunglernangebot: '',
      empfehlungklassenniveau: '',
      changedate: '',
      createdate: moment().format('YYYY-MM-DD')
    };
    // competences.createdate = moment().format('YYYY-MM-DD');
    return this.getPersonCompetenceDetail(personId)
      .set(competences)
      .then((data) => {
        this.createjournal('personcompetence', 'C', personId, '', '', '');
      });
  }
  updatePersonCompetence(personId, competences): any {
    competences.changedate = moment().format('YYYY-MM-DD');
    return this.getPersonCompetenceDetail(personId)
      .update(competences)
      .then((data) => {
        this.createjournal('personcompetence', 'U', personId, '', '', '');
      });
  }
  deletePersonCompetence(personId): any {
    return this.getPersonCompetenceDetail(personId)
      .remove()
      .then((data) => {
        this.createjournal('personcompetence', 'D', personId, '', '', '');
      });
  }

  // Präsenzliste
  // Schultag (inkl. Vormittag, nachmittag, abend info)
  getClassSectionList(clsId): any {
    return this.classsectionList.child(clsId);
  }
  createClassSection(clsId, presence): any {
    return this.getClassSectionList(clsId)
      .child(presence.key)
      .set(presence)
      .then((data) => {
        this.createjournal('classsection', 'C', presence.key, 'class', clsId, '');
      });
  }
  deleteClassSection(clsId, id): any {
    return this.getClassSectionList(clsId)
      .child(id)
      .remove()
      .then((data) => {
        this.createjournal('classsection', 'D', id, 'class', clsId, '');
      });
  }

  // Person pro Schultag
  getPersonPresenceList(personId): any {
    return this.personPresenceList.child(personId);
  }
  createPersonPresence(personId, presence): any {
    /*return this.getPersonPresenceList(personId).child(presence.key).once('value', snapshot => {
      if (snapshot.exists() === false){*/
    return this.getPersonPresenceList(personId)
      .child(presence.key)
      .set(presence)
      .then((data) => {
        this.createjournal('personpresence', 'C', presence.key, 'person', personId, '');
      });
    /*}
  }, presence);*/
  }
  updatePersonPresence(personId, presence, value): any {
    const pre = { presence: '' };
    pre.presence = value;

    return this.getPersonPresenceList(personId)
      .child(presence.id)
      .update(pre)
      .then((data) => {
        this.createjournal('personpresence', 'U', presence.key, 'person', personId, '');
      });
  }
  deletePersonPresence(personId, id): any {
    return this.getPersonPresenceList(personId)
      .child(id)
      .remove();
  }

  getNationalityList(): any {
    return this.nationalityList;
  }
  createNationality(nationality): any {
    return this.getNationalityList()
      .push(nationality)
      .then((data) => {
        this.afs.doc('nationalities/' + data.key).set(nationality, { merge: true });
      });
  }
  updateNationality(nationality): any {
    this.afs.doc('nationalities/' + nationality.id).set(nationality, { merge: true });
    return this.getNationalityList()
      .child(nationality.id)
      .update(nationality);
  }
  deleteNationality(id): any {
    this.afs.doc('nationalities/' + id).delete();
    return this.getNationalityList()
      .child(id)
      .remove();
  }

  getMaterialstatusList(): any {
    return this.materialstatusList;
  }
  createMaterialstatus(materialstatus): any {
    return this.getMaterialstatusList()
      .push(materialstatus)
      .then((item) => this.createjournal('materialstatus', 'C', item.key, '', '', ''));
  }
  updateMaterialstatus(materialstatus): any {
    return this.getMaterialstatusList()
      .child(materialstatus.id)
      .update(materialstatus);
  }
  deleteMaterialstatus(id): any {
    return this.getMaterialstatusList()
      .child(id)
      .remove();
  }
  getResidencestatusList(): any {
    return this.residencestatusList;
  }
  createResidencestatus(residencestatus): any {
    return this.getResidencestatusList()
      .push(residencestatus)
      .then((item) => {
        this.createjournal('residencestatus', 'C', item.key, '', '', '');
      });
  }
  updateResidencestatus(residencestatus): any {
    return this.getResidencestatusList()
      .child(residencestatus.id)
      .update(residencestatus);
  }
  deleteResidencestatus(id): any {
    return this.getResidencestatusList()
      .child(id)
      .remove();
  }
  getReligionList(): any {
    return this.religionList;
  }
  createReligion(religion): any {
    return this.getReligionList()
      .push(religion)
      .then((item) => {
        this.createjournal('religion', 'C', item.key, '', '', '');
      });
  }
  updateReligion(religion): any {
    return this.getReligionList()
      .child(religion.id)
      .update(religion);
  }
  deleteReligion(id): any {
    return this.getReligionList()
      .child(id)
      .remove();
  }
  getSupervisorList(): any {
    return this.supervisorList;
  }
  getAdministrativeworkerList(): any {
    return this.administrativeworkerList;
  }
  getAdministratorList(): any {
    return this.administratorList;
  }
  createSupervisor(supervisor): any {
    return this.getSupervisorList().push(supervisor);
  }
  updateSupervisor(supervisor): any {
    return this.getSupervisorList()
      .child(supervisor.id)
      .update(supervisor);
  }
  deleteSupervisor(id): any {
    return this.getSupervisorList()
      .child(id)
      .remove();
  }

  /*************************************************************************/
  // Translator List
  /*************************************************************************/

  getTranslatorList(): any {
    return this.translatorList;
  }
  createTranslator(translator): any {
    return this.getTranslatorList().push(translator);
  }
  updateTranslator(translator): any {
    return this.getTranslatorList()
      .child(translator.id)
      .update(translator);
  }
  deleteTranslator(id): any {
    return this.getTranslatorList()
      .child(id)
      .remove();
  }

  /*************************************************************************/
  // Language List
  /*************************************************************************/

  getLanguageList(): any {
    return this.languageList;
  }
  createLanguage(language): any {
    return this.getLanguageList()
      .push(language)
      .then((data) => {
        this.afs.doc('language/' + data.key).set(language, { merge: true });
      });
  }
  updateLanguage(language): any {
    this.afs.doc('language/' + language.id).set(language, { merge: true });
    return this.getLanguageList()
      .child(language.id)
      .update(language);
  }
  deleteLanguage(id): any {
    this.afs.doc('language/' + id).delete();
    return this.getLanguageList()
      .child(id)
      .remove();
  }

  /*************************************************************************/
  // Course List (Fachbereich)
  /*************************************************************************/
  getCourseList(): any {
    return this.courseList;
  }
  createCourse(course): any {
    return this.getCourseList()
      .push(course)
      .then((item) => {
        this.createjournal('course', 'C', item.key, '', '', '');
      });
  }
  updateCourse(course): any {
    return this.getCourseList()
      .child(course.id)
      .update(course)
      .then((data) => {
        this.createjournal('course', 'U', course.id, '', '', '');
      });
  }
  deleteCourse(id): any {
    return this.getCourseList()
      .child(id)
      .update({ active: false })
      .then((data) => {
        this.createjournal('course', 'D', id, '', '', '');
      });
  }
  undeleteCourse(id): any {
    return this.getCourseList()
      .child(id)
      .update({ active: true })
      .then((data) => {
        this.createjournal('course', 'A', id, '', '', '');
      });
  }

  /*************************************************************************/
  // Module List (Lernangebot)
  /*************************************************************************/
  getModuleList(): any {
    return this.moduleList;
  }
  createModule(language): any {
    return this.getModuleList()
      .push(language)
      .then((item) => {
        this.createjournal('module', 'C', item.key, '', '', '');
      });
  }
  updateModule(module): any {
    return this.getModuleList()
      .child(module.id)
      .update(module)
      .then((data) => {
        this.createjournal('module', 'U', module.id, '', '', '');
      });
  }
  deleteModule(id): any {
    return this.getModuleList()
      .child(id)
      .update({ active: false })
      .then((data) => {
        this.createjournal('module', 'D', id, '', '', '');
      });
  }
  undeleteModule(id): any {
    return this.getModuleList()
      .child(id)
      .update({ active: true })
      .then((data) => {
        this.createjournal('module', 'A', id, '', '', '');
      });
  }

  /*************************************************************************/
  // Class List (Klassen)
  /*************************************************************************/

  getClassList(): DatabaseReference {
    return this.classList;
  }
  createClass(cls): any {
    cls.yearsemester = cls.year + '-' + cls.semester;
    return this.getClassList()
      .push(cls)
      .then((item) => {
        this.createjournal('class', 'C', item.key, '', '', '');
      });
  }
  updateClass(cls): any {
    cls.yearsemester = cls.year + '-' + cls.semester;
    return this.getClassList()
      .child(cls.id)
      .update(cls)
      .then((data) => {
        this.createjournal('class', 'U', cls.id, '', '', '');
      });
  }
  deleteClass(id): any {
    return this.getClassList()
      .child(id)
      .update({ active: false })
      .then((data) => {
        this.createjournal('class', 'D', id, '', '', '');
      });
  }
  undeleteClass(id): any {
    return this.getClassList()
      .child(id)
      .update({ active: true })
      .then((data) => {
        this.createjournal('class', 'A', id, '', '', '');
      });
  }

  getClassPersonList(): DatabaseReference {
    return this.classPersonList;
  }
  createClassPerson(clsId, classPersonId): any {
    const that = this;
    // ***************************************************
    this.getPersonClassList(classPersonId)
      .child(clsId)
      .set(true);
    // ***************************************************

    const obj = {};
    let status = {};
    status = {
      person: classPersonId,
      class: clsId,
      active: true,
      joindate: moment().format('YYYY-MM-DD'),
      droppedout: false,
      dropoutdate: '',
      dropoutreason: ''
    };
    obj[classPersonId] = status;
    return this.getClassPersonList()
      .child(clsId)
      .update(obj)
      .then((data) => {
        that.createjournal('classperson', 'C', classPersonId, 'class', clsId, '');
      });
  }
  deleteClassPerson(clsId, id): any {
    const that = this;
    return this.getPersonClassList(id)
      .child(clsId)
      .remove()
      .then((data) => {
        that
          .getClassPersonList()
          .child(clsId)
          .child(id)
          .remove();
      })
      .then((data) => {
        that.createjournal('classperson', 'D', id, 'class', clsId, '');
      });
  }
  changeClassAssignment(clsIdOld, id, clsIdNew) {
    const that = this;
    // ***************************************************
    this.getPersonClassList(id)
      .child(clsIdOld)
      .set(false);
    this.getPersonClassList(id)
      .child(clsIdNew)
      .set(true);
    // ***************************************************
    let status = {};
    status = {
      active: false
    };

    this.getClassPersonList()
      .child(clsIdOld)
      .child(id)
      .update(status);

    const promise = this.createClassPerson(clsIdNew, id);
    return promise;
  }

  dropoutClassPerson(clsId, id, dropoutreason) {
    // ***************************************************
    this.getPersonClassList(id)
      .child(clsId)
      .set(false);
    // ***************************************************

    const obj = {
      active: false,
      droppedout: true,
      dropoutdate: moment().format('YYYY-MM-DD'),
      dropoutreason
    };

    return this.getClassPersonList()
      .child(clsId)
      .child(id)
      .update(obj)
      .then((data) => {
        this.createjournal('classperson', 'U', id, 'class', clsId, 'Dropout');
      });
  }

  getPersonClassList(personId) {
    return this.personClassList.child(personId);
  }

  reactivateClassPerson(clsId, id) {
    const obj = {
      active: true,
      droppedout: false,
      dropoutdate: '',
      dropoutreason: ''
    };

    return this.getClassPersonList()
      .child(clsId)
      .child(id)
      .update(obj)
      .then((data) => {
        this.createjournal('classperson', 'U', id, 'class', clsId, 'Reaktivieren');
      });
  }

  /*************************************************************************/
  // Competenceprofile List
  /*************************************************************************/
  getCompetenceprofileList(uid): any {
    return this.competenceprofileList.child(uid);
  }
  getCompetenceprofileDetail(uid, id): any {
    return this.getCompetenceprofileList(uid).child(id);
  }
  createCompetenceprofile(competenceprofile, uid): any {
    return this.getCompetenceprofileList(uid)
      .push(competenceprofile)
      .then((item) => {
        this.createjournal('competenceprofile', 'C', item.key, 'person', uid, '');
      });
  }
  updateCompetenceprofile(competenceprofile, uid): any {
    return this.getCompetenceprofileList(uid)
      .child(competenceprofile.id)
      .update(competenceprofile)
      .then((data) => {
        this.createjournal('competenceprofile', 'U', competenceprofile.id, 'person', uid, '');
      });
  }
  deleteCompetenceprofile(uid, id): any {
    return this.getCompetenceprofileList(uid)
      .child(id)
      .remove()
      .then((data) => {
        this.createjournal('competenceprofile', 'D', id, 'person', uid, '');
      });
  }

  /*************************************************************************/
  // Journal
  /*************************************************************************/
  getJournalList(): any {
    return this.journalList;
  }
  createjournal(object, action, objectid, relatedobject, relatedobjectid, description): any {
    // action , CRUD
    // object , Pfad in Firebase
    // id     , id of Object modified
    // description , freetext
    // timestamp , date and time of action
    const obj = {
      userid: this.userProfile.id,
      action,
      object,
      objectid,
      relatedobject,
      relatedobjectid,
      description,
      timestamp: Date.now()
    };
    const node = moment().format('YYYY-MM-DD');
    return this.journalList.child(node).push(obj);
  }

  /*************************************************************************/
  // Wartelisten
  /*************************************************************************/

  getWaitinglistList(): any {
    return this.waitinglistList;
  }
  createWaitinglist(cls): any {
    cls.yearsemester = cls.year + '-' + cls.semester;
    return this.getWaitinglistList()
      .push(cls)
      .then((item) => {
        this.createjournal('waitinglist', 'C', item.key, '', '', '');
      });
  }
  updateWaitinglist(cls): any {
    cls.yearsemester = cls.year + '-' + cls.semester;
    return this.getWaitinglistList()
      .child(cls.id)
      .update(cls)
      .then((data) => {
        this.createjournal('waitinglist', 'U', cls.id, '', '', '');
      });
  }
  deleteWaitinglist(id): any {
    return this.getWaitinglistList()
      .child(id)
      .update({ active: false })
      .then((data) => {
        this.createjournal('waitinglist', 'D', id, '', '', '');
      });
  }
  undeleteWaitinglist(id): any {
    return this.getWaitinglistList()
      .child(id)
      .update({ active: true })
      .then((data) => {
        this.createjournal('waitinglist', 'A', id, '', '', '');
      });
  }

  getWaitinglistPersonList(): any {
    return this.waitinglistPersonList;
  }
  createWaitinglistPerson(clsId, classPersonId): any {
    // ***************************************************
    this.getPersonWaitinglistList(classPersonId)
      .child(clsId)
      .set(true);
    // ***************************************************

    const obj = {};
    let status = {};
    status = {
      person: classPersonId,
      waitinglist: clsId,
      joindate: moment().format('YYYY-MM-DD')
    };
    obj[classPersonId] = status;
    return this.getWaitinglistPersonList()
      .child(clsId)
      .update(obj);
  }
  deleteWaitinglistPerson(clsId, id): any {
    this.getPersonWaitinglistList(id)
      .child(clsId)
      .set(null);
    return this.getWaitinglistPersonList()
      .child(clsId)
      .child(id)
      .remove();
  }

  getPersonWaitinglistList(personId) {
    return this.personWaitinglistList.child(personId);
  }

  joinClassFromWaitinglist(waitinglistId, id, clsIdNew) {
    // ***************************************************
    this.getPersonWaitinglistList(id)
      .child(waitinglistId)
      .set(null);
    // ***************************************************
    this.getWaitinglistPersonList()
      .child(waitinglistId)
      .child(id)
      .set(null);

    return this.createClassPerson(clsIdNew, id);
  }

  /*************************************************************************/
  // Einstufungstests
  /*************************************************************************/

  getPlacementtestList(): any {
    return this.placementtestList;
  }
  createPlacementtest(cls): any {
    cls.yearsemester = cls.year + '-' + cls.semester;
    cls.testdate = cls.testdate.format('YYYY-MM-DD');
    return this.getPlacementtestList()
      .push(cls)
      .then((item) => {
        this.createjournal('placementtest', 'C', item.key, '', '', '');
      });
  }
  updatePlacementtest(cls): any {
    cls.yearsemester = cls.year + '-' + cls.semester;
    if (cls.testdate && cls.testdate._isAMomentObject === true) {
      cls.testdate = cls.testdate.format('YYYY-MM-DD');
    }

    return this.getPlacementtestList()
      .child(cls.id)
      .update(cls)
      .then((data) => {
        this.createjournal('placementtest', 'U', cls.id, '', '', '');
      });
  }
  deletePlacementtest(id): any {
    return this.getPlacementtestList()
      .child(id)
      .update({ active: false })
      .then((data) => {
        this.createjournal('placementtest', 'D', id, '', '', '');
      });
  }
  undeletePlacementtest(id): any {
    return this.getPlacementtestList()
      .child(id)
      .update({ active: true })
      .then((data) => {
        this.createjournal('placementtest', 'A', id, '', '', '');
      });
  }

  getPlacementtestPersonList(): any {
    return this.placementtestPersonList;
  }
  createPlacementtestPerson(clsId, classPersonId): any {
    // ***************************************************
    this.getPersonPlacementtestList(classPersonId)
      .child(clsId)
      .set(true);
    // ***************************************************

    const obj = {};
    let status = {};
    status = {
      person: classPersonId,
      waitinglist: clsId,
      joindate: moment().format('YYYY-MM-DD')
    };
    obj[classPersonId] = status;
    return this.getPlacementtestPersonList()
      .child(clsId)
      .update(obj);
  }
  deletePlacementtestPerson(clsId, id): any {
    this.getPersonPlacementtestList(id)
      .child(clsId)
      .set(null);
    return this.getPlacementtestPersonList()
      .child(clsId)
      .child(id)
      .remove();
  }

  getPersonPlacementtestList(personId) {
    return this.personPlacementtestList.child(personId);
  }

  joinWaitinglistFromPlacementtest(placementtestId, id, waitinglistId) {
    // ***************************************************
    this.getPersonPlacementtestList(id)
      .child(placementtestId)
      .set(null);
    // ***************************************************
    this.getPlacementtestPersonList()
      .child(placementtestId)
      .child(id)
      .set(null);

    return this.createWaitinglistPerson(waitinglistId, id);
  }

  joinClassFromPlacementtest(placementtestId, id, classId) {
    // ***************************************************
    this.getPersonPlacementtestList(id)
      .child(placementtestId)
      .set(null);
    // ***************************************************
    this.getPlacementtestPersonList()
      .child(placementtestId)
      .child(id)
      .set(null);

    return this.createClassPerson(classId, id);
  }

  getValueHelpers(): any {
    // Wertehilfen
    const that = this;
    this.literacylevelList = [
      { key: 'AL.0', text: 'AL.0' },
      { key: 'AL.1', text: 'AL.1' },
      { key: 'AL.2', text: 'AL.2' },
      { key: 'AL.3', text: 'AL.3' }
    ];

    this.mathNiveau = [
      { key: '1', text: '1' },
      { key: '1.5', text: '1.5' },
      { key: '2', text: '2' },
      { key: '2.5', text: '2.5' },
      { key: '3', text: '3' },
      { key: '3.5', text: '3.5' },
      { key: '4', text: '4' },
      { key: '4.5', text: '4.5' },
      { key: '5', text: '5' },
      { key: '5.5', text: '5.5' },
      { key: '6', text: '6' }
    ];

    this.verhaltensbeobachtungenNiveau = [
      { key: '1', text: 'nicht erfüllt' },
      { key: '4', text: 'teilweise erfüllt' },
      { key: '7', text: 'erfüllt' },
      { key: '10', text: 'übertroffen' }
    ];

    this.niveauGermanLanguage = [
      { key: 'A0', text: 'A0' },
      { key: 'A1.1', text: 'A1.1' },
      { key: 'A1.1+', text: 'A1.1+' },
      { key: 'A1.2', text: 'A1.2' },
      { key: 'A1.2+', text: 'A1.2+' },
      // { key: 'A1', text: 'A1 Prüfung' },
      { key: 'A2.1', text: 'A2.1' },
      { key: 'A2.1+', text: 'A2.1+' },
      { key: 'A2.2', text: 'A2.2' },
      { key: 'A2.2+', text: 'A2.2+' },
      // { key: 'A2', text: 'A2 Prüfung' },
      { key: 'B1.1', text: 'B1.1' },
      { key: 'B1.2', text: 'B1.2' }
      // { key: 'B1', text: 'B1 Prüfung' }
    ];

    this.niveauGermanLanguageAll = [
      { key: 'AL.0', text: 'AL.0' },
      { key: 'AL.1', text: 'AL.1' },
      { key: 'AL.2', text: 'AL.2' },
      { key: 'AL.3', text: 'AL.3' },
      { key: 'A0', text: 'A0' },
      { key: 'A1.1', text: 'A1.1' },
      { key: 'A1.1+', text: 'A1.1+' },
      { key: 'A1.2', text: 'A1.2' },
      { key: 'A1.2+', text: 'A1.2+' },
      // { key: 'A1', text: 'A1 Prüfung' },
      { key: 'A2.1', text: 'A2.1' },
      { key: 'A2.1+', text: 'A2.1+' },
      { key: 'A2.2', text: 'A2.2' },
      { key: 'A2.2+', text: 'A2.2+' },
      // { key: 'A2', text: 'A2 Prüfung' },
      { key: 'B1.1', text: 'B1.1' },
      { key: 'B1.2', text: 'B1.2' }
      // { key: 'B1', text: 'B1 Prüfung' }
    ];

    this.genericScalaOnetoTen = [
      { key: '1', text: '1' },
      { key: '2', text: '2' },
      { key: '3', text: '3' },
      { key: '4', text: '4' },
      { key: '5', text: '5' },
      { key: '6', text: '6' },
      { key: '7', text: '7' },
      { key: '8', text: '8' },
      { key: '9', text: '9' },
      { key: '10', text: '10' }
    ];

    this.LernundLebenssituation = [
      { key: 'ungünstig', text: 'ungünstig' },
      { key: 'intermediär', text: 'intermediär' },
      { key: 'günstig', text: 'günstig' }
    ];

    this.Selbstbeurteilung = [
      { key: 'unterschätzt sich', text: 'unterschätzt sich' },
      { key: 'realistisch', text: 'realistisch' },
      { key: 'überschätzt sich', text: 'überschätzt sich' }
    ];

    this.Praesenz = [
      { key: '10', text: '0 - 59%' },
      { key: '20', text: '60 - 79%' },
      { key: '30', text: '80 - 100%' }
    ];

    this.Lernangebot = [];

    this.getModuleList().once(
      'value',
      (snapshot) => {
        snapshot.forEach((snap) => {
          const object = snap.val();
          object.id = snap.key;
          that.Lernangebot.push(object);
        });
      },
      (err) => {
        // that.ms.alertError('Fehler: ' + (err as any).code, err.message );
      }
    );

    this.lerngewohntheit = [
      { key: 'lernungewohnt', text: 'lernungewohnt' },
      { key: 'lerngewohnt', text: 'lerngewohnt' }
    ];
  }

  /*************************************************************************/
  // Temp. Migrations etc.
  /*************************************************************************/
  fix(): any {
    // this.fixPersonClass();
    // this.fixClass();
    // this.fixWaitinglist();
    // this.fixPlacementtest();
    // this.fixPersonBirthdate();
    // this.fixPersonDateofimmigration();
    // this.fixPersonCompetence();
    // this.fixActiveUseracounts();
    // this.fixCompetenceprofileversion();
  }
  fixPersonClass(): any {
    // classpersons
    const that = this;
    this.getClassPersonList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        const object = snap.val();
        // object.id = snap.key;

        snapshot.forEach((snap2) => {
          console.log(snap2.val());

          const blaobject = snap2.val();

          for (const property in blaobject) {
            if (blaobject.hasOwnProperty(property)) {
              // do stuff
              const classperson = blaobject[property];
              console.log(blaobject[property]);

              if (classperson.active === true) {
                that
                  .getPersonClassList(classperson.person)
                  .child(classperson.class)
                  .set(true);
              } else {
                that
                  .getPersonClassList(classperson.person)
                  .child(classperson.class)
                  .set(false);
              }
              that
                .getPersonClassList(classperson.person)
                .child('2016-H')
                .set(null);
              that
                .getPersonClassList(classperson.person)
                .child('undefined-undefined')
                .set(null);
            }
          }
        });
      }); // context dDATA
    }); // context dDATA
  }

  fixCompetenceprofileversion(): any {
    // classpersons
    const that = this;
    this.competenceprofileList.once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        // var object = snap.val();

        that.getCompetenceprofileList(snap.key).once('value', (snapshot2) => {
          snapshot2.forEach((snap2) => {
            const object = snap2.val();

            if (!object.version) {
              object.version = '1';
              console.log(object);
              console.log(snap.key);
              console.log(snap2.key);
              that
                .getCompetenceprofileList(snap.key)
                .child(snap2.key)
                .update(object)
                .then((data) => console.log('success'));
            }
          }); // context dDATA
        }); // context dDATA
      });
    });
  }
  fixClass(): any {
    // classpersons
    const that = this;
    this.getClassList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        const object = snap.val();

        if (object.semester === '') {
          object.semester = 'H';
        }
        object.yearsemester = object.year + '-' + object.semester;
        // console.log(object.yearsemester);
        that
          .getClassList()
          .child(snap.key)
          .update(object);
      }); // context dDATA
    }); // context dDATA
  }

  fixWaitinglist(): any {
    // classpersons
    const that = this;
    this.getWaitinglistList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        const object = snap.val();

        if (object.semester === '') {
          object.semester = 'H';
        }
        object.yearsemester = object.year + '-' + object.semester;
        // console.log(object.yearsemester);
        that
          .getWaitinglistList()
          .child(snap.key)
          .update(object);
      }); // context dDATA
    }); // context dDATA
  }

  fixPlacementtest(): any {
    // classpersons
    const that = this;
    this.getPlacementtestList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        const object = snap.val();

        if (object.semester === '') {
          object.semester = 'H';
        }
        object.yearsemester = object.year + '-' + object.semester;
        // console.log(object.yearsemester);
        that
          .getPlacementtestList()
          .child(snap.key)
          .update(object);
      }); // context dDATA
    }); // context dDATA
  }

  fixPersonBirthdate() {
    const that = this;
    this.getPersonList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        const object = snap.val();

        if (object.birthdate.indexOf('.') > -1) {
          object.birthdate = moment(object.birthdate).format('YYYY-MM-DD');

          if (object.birthdate === 'Invalid date') {
            object.birthdate = '';
          }
          that
            .getPersonList()
            .child(snap.key)
            .update(object);
        }
      }); // context dDATA
    }); // context dDATA
  }

  fixPersonDateofimmigration() {
    const that = this;
    this.getPersonList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        const object = snap.val();

        if (object.dateofimmigration.indexOf('.') > -1) {
          object.dateofimmigration = moment(object.dateofimmigration).format('YYYY-MM-DD');

          if (object.dateofimmigration === 'Invalid date') {
            object.dateofimmigration = '';
          }
          that
            .getPersonList()
            .child(snap.key)
            .update(object);
        }
      }); // context dDATA
    }); // context dDATA
  }

  fixPersonCompetence() {
    const that = this;
    this.getPersonList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        that.getPersonCompetenceDetail(snap.key).once('value', (snapshot2) => {
          const object = snapshot2.val();

          if (object.deutschniveau && !object.stufederalphabetisierung) {
            object.stufederalphabetisierung = 'AL.3';
          }

          if (!object.deutschniveau && object.stufederalphabetisierung) {
            object.deutschniveau = 'A0';
          }

          if (!object.lernundLebenssituation) {
            object.lernundLebenssituation = 'günstig';
          }

          that.getPersonCompetenceDetail(snapshot2.key).update(object);
        }); // context dDATA
      }); // context dDATA
    }); // context dDATA
  }

  fixActiveUseracounts() {
    const that = this;
    this.getUseraccountList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        that
          .getUseraccountList()
          .child(snap.key)
          .update({ active: true });
      }); // context dDATA
    }); // context dDATA

    this.getClassList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        that
          .getClassList()
          .child(snap.key)
          .update({ active: true });
      }); // context dDATA
    }); // context dDATA

    this.getWaitinglistList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        that
          .getWaitinglistList()
          .child(snap.key)
          .update({ active: true });
      }); // context dDATA
    }); // context dDATA

    this.getPersonList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        that
          .getPersonList()
          .child(snap.key)
          .update({ active: true });
      }); // context dDATA
    }); // context dDATA

    this.getModuleList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        that
          .getModuleList()
          .child(snap.key)
          .update({ active: true });
      }); // context dDATA
    }); // context dDATA

    this.getCourseList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        that
          .getCourseList()
          .child(snap.key)
          .update({ active: true });
      }); // context dDATA
    }); // context dDATA

    this.getPlacementtestList().once('value', (snapshot) => {
      snapshot.forEach((snap) => {
        that
          .getPlacementtestList()
          .child(snap.key)
          .update({ active: true });
      }); // context dDATA
    }); // context dDATA
  }

  loadingStart() {
    this.loading = true;
  }
  loadingEnd() {
    this.loading = false;
  }
}
